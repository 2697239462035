import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";

const ContactPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Contact" theme={theme} />
            </header>

            <div className="bodytext">
              <p>
                Have you noticed a problem within our data? Are you using our API in your business?
                Do you need additional related data? Contact us with the below form.
              </p>
            </div>

            <Contact theme={theme} />

            <style jsx>{`
              .bodytext {
                animation-name: bodytextEntry;
                animation-duration: ${theme.time.duration.long};

                :global(th) {
                  min-width: 70px;
                }

                :global(h2),
                :global(h3) {
                  margin: 1.5em 0 1em;
                }

                :global(h2) {
                  line-height: ${theme.font.lineHeight.s};
                  font-size: ${theme.font.size.l};
                }

                :global(h3) {
                  font-size: ${theme.font.size.m};
                  line-height: ${theme.font.lineHeight.m};
                }

                :global(p) {
                  font-size: ${theme.font.size.s};
                  line-height: ${theme.font.lineHeight.xxl};
                  margin: 0.75em 0;
                }
                :global(ul) {
                  list-style: circle;
                  margin: 0.75em 0;
                  padding: 0.75em 0;
                }
                :global(li) {
                  margin: 0.7em 0;
                  line-height: 1.5;
                }
                :global(a) {
                  font-weight: ${theme.font.weight.bold};
                  color: ${theme.color.brand.primary};
                  text-decoration: underline;
                }
                :global(a.gatsby-resp-image-link) {
                  border: 0;
                  display: block;
                  margin: 2.5em 0;
                  border-radius: ${theme.size.radius.default};
                  overflow: hidden;
                  border: 1px solid ${theme.line.color};
                }
                :global(code.language-text) {
                  background: ${theme.color.neutral.gray.c};
                  text-shadow: none;
                  color: inherit;
                  padding: 0.1em 0.3em 0.2em;
                  border-radius: 0.1em;
                }
              }

              @keyframes bodytextEntry {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              }
            `}</style>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
    </React.Fragment>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
